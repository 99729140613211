import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as mapData from './mapData';
import * as disable from './disable';
import * as colors from './colors';
import drawStyles from './drawStyles';


let map;
let initialized = false;
let existing = false;


const Draw = new MapboxDraw({
  displayControlsDefault: false,
  styles: drawStyles
});


const setupTrailRouteMap = () => {
  const container = document.getElementById('draw-route-map');
  if(container) {
    map = mapData.setupMap(container);
    map.on('load', loadExistingRoute);
    map.on('load', () => mapData.addControls(map, 'top-right'));
    // map.on('load', (map) => { mapData.setupGCBounds(map) });
    map.on('load', map.addControl(Draw));
    map.on('draw.modechange', checkMode);
    map.on('draw.selectionchange', checkDeleteMode);
    map.on('draw.update', setSave);
    map.on('draw.create', setSave);
    map.on('draw.delete', setSave);
    // map.on('click', () => console.log(Draw.getSelected()));
  }
}


// check if a Route exists (i.e in EDIT mode rather than CREATE)
// Render it and set 'existing' (used by activateClicked) if so
const loadExistingRoute = () => {
  const route = document.getElementById('trail_json_route').value;
  if(route){
    const parsed = JSON.parse(route);
    console.log('about to set');
    Draw.set(parsed);
    console.log('after set');
    mapData.zoomToRoute(map, parsed)
    existing = true;
  }
}


// return Activate elements clicked ?
// using initialized (see above), method will look for both Edit button
// Get Started button when first loaded.
// after initialised, it will ignore the Get Started button
const activateClicked = (e) => {
  const el = e.target;
  if(initialized || existing){
    return el.parentElement.id === 'activate-draw' || el.id === 'activate-draw';
  } else {
    const result = el.id === 'get-started' || el.parentElement.id === 'activate-draw';
    if(result) initialized = true;
    return result;
  }
}


// return Delete elements clicked ?
const deleteClicked = (e) => {
  const el = e.target;
  return el.id === 'delete-button' || el.parentElement.id === 'delete-button';
}


const drawDeactivated = () => {
  return Draw.getMode() !== 'draw_line_string';
}


// when Draw clicked, check if there are Features on map.
// if so, select the Feature. Otherwise, activate Draw mode
const handleDrawClicked = (e) => {
  if(activateClicked(e) && map && drawDeactivated()){
    // if the map has features ...
    if(mapHasFeatures()){
      // ... and nothing selected, then select the features
      if(!featureSelected()){
        const toSelectId = Draw.getAll().features[0].id;
        Draw.changeMode('simple_select', { featureIds: [toSelectId] });
        disable.set('delete-button', false)
      }

    } else {
      // if map has no features, activate Draw tool
      activateDraw();
    }
  }
}


const mapHasFeatures = () => {
  const selected = Draw.getAll();
  if(selected){
    const { features } = selected;
    return features.length > 0;
  } else {
    throw 'Draw.getAll() returned nothing'
  }
}


const featureSelected = () => {
  const selected = Draw.getSelected();
  if(selected){
    const { features } = selected;
    return features.length > 0;
  } else {
    throw 'Draw.getSelected() returned nothing'
  }
}


// set Drawing mode, disable Draw button
const activateDraw = () => {
  Draw.changeMode('draw_line_string');
  disable.set('activate-draw', true);
}


// disable Draw button when not in Draw mode
const checkMode = () => {
  if(drawDeactivated()) disable.set('activate-draw', false);
  else disable.set('activate-draw', true);
}


const setSave = () => {
  mapHasFeatures() ? disable.set('save-button', false) : disable.set('save-button', true);
}


// set Delete button disabled/enabled based on selection
const checkDeleteMode = () => {
  featureSelected() ? disable.set('delete-button', false) : disable.set('delete-button', true);
  if(!mapHasFeatures) disable.set('delete-button', true)
}


// const checkSelection = () => {
//   if(featureSelected()) Draw.changeMode('direct_select');
// }


// delete Selected feature (line or point)
const deleteSelected = (e) => {
  if(deleteClicked(e) && map){
    // Ask for confirmation if the route is selected
    if(Draw.getMode() === 'simple_select'){
      if(confirm("You've selected the whole route. Are you sure you want to delete it?")){
        Draw.trash();
      }
    // no confirmation required if its just a Point
    } else {
      Draw.trash();
    }
  }
}



const toggleStyle = (e) => {
  const el = e.target;
  const body = document.getElementsByTagName('body')[0];
  // restrict this to Homepage
  if(body.classList.contains('trails') && body.classList.contains('route')){
    if(el.id === 'satellite-style-switch'){
      mapData.setSatStyle(map);
    } else if (el.id === 'map-style-switch'){
      mapData.setMapStyle(map);
    }
  }
}


document.addEventListener('click', handleDrawClicked)
document.addEventListener('click', deleteSelected)
document.addEventListener('turbolinks:load', setupTrailRouteMap)
document.addEventListener('click', toggleStyle);


export default Draw;
