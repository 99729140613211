//  USED IN:
// - trails#show, #edit, etc , and POINT

import mapboxgl from 'mapbox-gl';
import { featureCollection } from '@turf/helpers';
import * as mapData from './mapData';
import * as ajax from './ajax';
import * as get from './get';
import * as visibility from './visibility';
import _ from 'lodash';
import * as measure from './measure';

let map;
let marker;
let loaded;


const setupTrailMap = (el) => {
  const container = document.getElementById('trail-map');
  if(container){
    loaded = false;
    map = mapData.setupMap(container);
    map.on('load', () => {
      mapData.addControls(map, 'top-right')
      fetchTrailData();
      if(canAddPoints(map)) map.on('click', addMarker);
    });
  }
}


// Render the Route & Points
const fetchTrailData = () => {
  const id = get.getId('trail');
  ajax.getJSON('/api/trails/' + id, handleTrailFetchSuccess, handleTrailFetchFailure);
}


const handleTrailFetchSuccess = (trail) => {
  renderRoute(trail);
  renderPoints(trail);
  removeLoadingModal()
}


const handleTrailFetchFailure = (res) => {
  removeLoadingModal()
  console.log('Error Fetching Trail');
  console.log(res);
  renderFailureMessage()
}


const renderFailureMessage = () => {
  alert('There was a problem loading this data. Please try again. If the problem persists, please contact tech@placesthatweknow.org.')
}



const renderRoute = (trail) => {
  const { json_route } = trail;
  if(json_route){
    const route = JSON.parse(json_route);
    mapData.addLine(map, route, `trail`);

    // THIS HAS STOPPED WORKING ???
    // MAPBOX CLAIMS THE LNGLAT IS INVALID
    // only do Zoom on first load
    if(!loaded) mapData.zoomToRoute(map, route);
    loaded = true;
  }
}


// POINTS

const renderPoints = (trail) => {
  let points;
  // if Editing, remove the Point that is being Edited and render seperately
  if(canEditPoints(map)) {
    const split = splitEditingPoint(trail.points);
    points = split.points
    createMarker(split.editing.geometry.coordinates);
  } else {
    points = trail.points;
  }

  if(points){
    loadPointImage();
    mapData.addSymbolLayer(map, points, 'points');
    map.setLayoutProperty('points', 'icon-size', 0.32);
    map.setLayoutProperty('points', 'icon-offset', [5, 5]);
    map.setLayoutProperty('points', 'icon-allow-overlap', true);
    // No Popups on Add or Edit page
    if(!canAddPoints(map) && !canEditPoints(map)) mapData.setupPopup(map, 'points');
  }
}



const removeLoadingModal = () => {
  visibility.hideById('loading-modal');
}



const loadPointImage = () => {
  map.loadImage(
    'https://garnock-ptwk-ica.s3.eu-west-2.amazonaws.com/neutral.png',
    (e, image) => {
      if(e) throw e;
      map.addImage('neutral', image);
    }
  )
}


const canAddPoints = (map) => {
  return map._container.classList.contains('add-point-map');
}



// BASICALLY THE SAME
const canEditPoints = (map) => {
  return map._container.classList.contains('edit-point-map');
}
const onEditPage = () => {
  return document.querySelectorAll('.edit-point-map').length === 0;
}


const renderEditingPoint = (point) => {
  createMarker(point.geometry.coordinates);
}


// accept Trail points - strips Point being edited and returns both in {}
const splitEditingPoint = (points) => {
  const pointsArray = points.features; // extract points from FeatureCollection
  const id = get.getId('point'); // pull ID from HTML
  const point = get.byId(pointsArray, id); // get Edit Point
  // remove Edit Point from Points
  const filtered = _.remove(pointsArray, (p) => p.properties.id !== id);

  return {
    editing: point,
    points: featureCollection(filtered) // reformat in FeatureCollection
  }
}


// MARKERS


const addMarker = (e) => {
  const coords = mapData.getCoords(e);
  if(coords) {
    createMarker(coords);
    removeAddMarkerListener(); // detach so only 1 marker can be created
    addChangeMarkerListener();
  }
}


const createMarker = (coords) => {
  const el = document.createElement('div');
  // Marker image inserted via CSS background-image
  el.className = 'point-marker';
  marker = new mapboxgl.Marker(el, {
    draggable: true,
    anchor: 'bottom',
    offset: [5, 5]
  });
  marker.on('dragend', getMarkerCoords);
  marker.setLngLat(coords)
  marker.addTo(map);
  attachCoordsToForm(coords);
  centerOnMarkerForSmall(coords);
}


const removeAddMarkerListener = () => {
  map.off('click', addMarker)
}


const addChangeMarkerListener = () => {
  map.on('click', setMarkerCoords);
}


const setMarkerCoords = (e) => {
  const coords = mapData.getCoords(e);
  if(coords) {
    marker.setLngLat(coords);
    attachCoordsToForm(coords);
    centerOnMarkerForSmall(coords);
  }
}


const getMarkerCoords = (e) => {
  const coords = mapData.getCoords(e.target);
  attachCoordsToForm(coords);
  centerOnMarkerForSmall(coords);
}



const attachCoordsToForm = (coords) => {
  const latField = get.elementsWhereIdIncludes('_geolocation_attributes_lat');
  const lngField = get.elementsWhereIdIncludes('_geolocation_attributes_lng');
  latField.value = coords[1]
  lngField.value = coords[0]
}



const toggleStyle = (e) => {
  const el = e.target;
  const body = document.getElementsByTagName('body')[0];

  if(body.classList.contains('points') || body.classList.contains('trails')){
    if(el.id === 'satellite-style-switch'){
      map.on('style.load', fetchTrailData);
      mapData.setSatStyle(map);
    } else if (el.id === 'map-style-switch'){
      map.on('style.load', fetchTrailData);
      mapData.setMapStyle(map);
    }
  }
}

const centerOnMarkerForSmall = (coords) => {
  // Apply offset as Small screens are mostly taken up with form
  coords[1] = coords[1] += 0.01;
  if(measure.onSmall()) map.easeTo({ center: coords });
}


document.addEventListener('turbolinks:load', setupTrailMap);
document.addEventListener('click', toggleStyle);
