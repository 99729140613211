// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
require('aframe');
require('foundation-sites');


// We need to restart Foundation after some AJAX calls, so we wrap this in a 
// method and export it at the bottim of the file.

const startFoundation = () => {
  $(function() { $(document).foundation(); });
}

$(document).on('turbolinks:load', function() {
  startFoundation();
});

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require("app/analytics.js.erb");
require("app/addGame");
require("app/audioPlayer");
require("app/checkToggle");
require("app/closeFlash");
require("app/copyrightFields");
require("app/display_email");
require("app/direct_uploads");
require("app/drawTrailRoute");
require("app/init_mapbox");
require("app/jiggle");
require("app/multipleChoiceFormToggler");
require("app/removeElement");
require("app/saveRoute");
require("app/insertNewAssociationFieldAtEndOfAssociations");
require("app/setDataIndexForMediaInputs");
require("app/setItemNumberInNestedForms");
require("app/shiftAddAssociationButtonAfterInsert");
require("app/scrollTo");
require("app/scrollDownHomepage");
require("app/showLocationSetMessage");
require("app/sizePDFPreview");
require("app/submitFormOnChange");
// require("app/toggleDownloadFields"); DEPRECATED no longer use media_type
require("app/toggleTarget");
require("app/trailMap");
require("app/triggerFileAttached");
require("app/quizLoginPrompt");
require("app/trackSlider");
require("app/aFramePlayOnClick");
require("app/aFrameHideOnPlay");
require("app/square");
require("app/sculptureMap");
require("app/mySlideshow");
require("app/toggleAudioIndexFields");
require("app/loadVrAsync");
require("app/basicSwiper");
require("app/clickToMaximise");

// require("app/vrPopup"); # DEPRECATED, this engagement exercise is finished


// TEST if JQUERY works
// $(function () {
//   console.log('Hello World from Webpacker');
// });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

// EXPORTS

// Occasionally we need to call methods in our js.erb view, so we export here.

import submitFormOnChange from '../app/submitFormOnChange'

export {
  submitFormOnChange,
  startFoundation
}