import React from "react"
import PropTypes from "prop-types"
import Modal from "./Modal";

class QuizComplete extends React.Component {
  render () {
    if(this.props.visible) return (
      <Modal
        onModalClose={this.props.onModalClose}
        innerContainerClasses='modal-inner-container cell small-10 medium-8 large-6 xlarge-5 xxlarge-4 white-texture-background text-center'
      >
        <div className="star"/>

        <p className="h1">Congratulations!</p>
        <p className="h3 lmb">
          You have completed all the questions for this trail
        </p>

        <div className="grid-x align-spaced">
          <a 
            href={`/trails/${this.props.trailId}`} 
            className="button graph-bg graph-link smb cell medium-8 large-5 xlarge-4"
          >
            <i className="fa fa-arrow-left smr" aria-hidden="true"></i>
            Back To Trail
          </a>

          <a 
            href="/trails/instructions"
            className="button graph-bg graph-link smb cell medium-8 large-5 xlarge-4"
          >
            <i className="fa fa-plus smr" aria-hidden="true"></i>
            Create a Quiz
          </a>
        </div>
      </Modal>
    ); else return null;
  } 
}

QuizComplete.propTypes = {
  trailId: PropTypes.number,
  visible: PropTypes.bool,
  onModalClose: PropTypes.func,
};
export default QuizComplete
