const aws = 'https://garnock-ptwk-ica.s3.eu-west-2.amazonaws.com/'

export const mapImages = {
  // AMENITIES
  ferry: `${aws}ferry.png`,
  train: `${aws}train.png`,
  parking: `${aws}parking.png`,
  playpark: `${aws}playpark.png`,
  library: `${aws}library.png`,
  church: `${aws}church.png`,
  gurdwara: `${aws}gurdwara.png`,
  school: `${aws}school.png`,
  cemetery: `${aws}cemetery.png`,
  project: `${aws}project.png`,
  // adding new
  point: `${aws}point.png`,
}
